import './skill-card.scss';
import DotnetCoreIcon from '../../Assets/DotnetCore.svg';
import AzureFunctionsIcon from '../../Assets/FunctionApps.svg';
import AzureDevopsIcon from '../../Assets/AzureDevops.svg';
import AzureIcon from '../../Assets/Azure.svg';
import HTMLIcon from '../../Assets/HTML.svg';
import CSSIcon from '../../Assets/CSS.svg';
import SASSIcon from '../../Assets/SASS.svg';
import AngularIcon from '../../Assets/Angular.svg';
import GitIcon from '../../Assets/Git.svg';
import RestApisIcon from '../../Assets/RestApis.svg';

function SkillCard({ name }: { name: string }) {
	const skillIcons: any = {
		'dotnet core': DotnetCoreIcon,
		azure: AzureIcon,
		'azure devops': AzureDevopsIcon,
		'azure functions': AzureFunctionsIcon,
		html: HTMLIcon,
		css: CSSIcon,
		sass: SASSIcon,
		angular: AngularIcon,
		git: GitIcon,
		'rest apis': RestApisIcon,
	};

	return (
		<div className="SkillCardContainer">
			<div className="SkillCardImage">
				<img
					className="SkillCard"
					src={skillIcons[name]}
					alt={`${name} logo`}
				/>
			</div>
			<div>
				<span>{name.toUpperCase()}</span>
			</div>
		</div>
	);
}

export default SkillCard;
