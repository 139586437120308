import { useEffect } from 'react';
import './about-page.scss';
import photo from '../../Assets/portfolio-portrait.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Codewars from '../../Assets/Codewars.svg';
import LinkedIn from '../../Assets/LinkedIn.svg';

function AboutPage() {
	useEffect(() => {
		//preload images in this function
	}, []);

	return (
		<div className="AboutPageOuterContainer" id="about">
			<div className="AboutPageHeader">
				<h1>About Me</h1>
			</div>
			<div className="AboutPageContent">
				<div className="AboutPageImage">
					<img src={photo} alt="Portrait of myself" />
				</div>
				<div className="AboutPagePersonalDescription">
					<p>
						Hello I'm Mitchell and I'm a Software Developer based in Gateshead.
						With 3 and a half years of development experience in C#, .NET,
						Angular, React.js and Azure. My journey began with a big focus on
						backend development using ASP.NET MVC which then led to creating API
						serverless functions with Azure Function Apps. I have always had a
						passion for backend development including CI/CD integration with the
						cloud.
					</p>
					<p>
						I also have a new found interest in front end development. Since I
						have started using React.js I am wanting to learn more and more each
						day. I first set myself the challenge of creating my portfolio with
						React.js to learn the basics.
					</p>
					<ul>
						<li>
							<a href="https://github.com/MChapman1998">
								<FontAwesomeIcon icon={faGithub} />
							</a>
						</li>
						<li>
							<a href="https://www.linkedin.com/in/mitch-chapman/">
								{/* <FontAwesomeIcon icon={faLinkedin} /> */}
								<img src={LinkedIn} alt="LinkedIn" />
							</a>
						</li>
						<li>
							<a href="https://www.codewars.com/users/MChapman1998">
								{/* <FontAwesomeIcon icon={faCode} /> */}
								<img src={Codewars} alt="LinkedIn" />
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default AboutPage;
